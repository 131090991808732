<template>
  <div class="container-fluid">
    <div class="row">
      <basic-card>
        <template #header>
          <h5>Poll-vraag</h5>
        </template>

        <material-input 
          v-model="title"
          class="mb-2"
          label="Vraag"
          variant="static"
        />

        <material-input
          v-model="description"
          class="mb-2"
          label="Beschrijving"
          variant="static"
        />

        <material-input
          v-model="endDate"
          type="date"
          label="Einddatum"
          variant="static"
        />

        <template #footer>
          <button 
            class="btn btn-sm btn-dark float-end"
          >
            Opslaan
          </button>
        </template>
      </basic-card>
    </div>

    <div class="row mt-4">
      <div class="col-6 ps-0">
        <poll-members 
          ref="pollMembersRef"
          v-model:is-restricted="isRestricted"
          v-model:users="invitedUsers"
          v-model:groups="invitedGroups"
        />
      </div>
      <div class="col-6 pe-0">
        <basic-card>
          <template #header>
            <h5>Opties</h5>
          </template>

          <poll-answers ref="pollAnswersRef" />
        </basic-card>
      </div>
    </div>

    <material-button color="primary" class="float-end mt-4 mx-0" @click.prevent="submit">
      Opslaan
    </material-button>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import BasicCard from '@/components/UI/BasicCard.vue';
import MaterialButton from '@/components/MaterialButton.vue';
import MaterialInput from '@/components/MaterialInput.vue';
import PollAnswers from '@/components/Poll/New/PollAnswers.vue';
import PollMembers from '@/components/Poll/New/PollMembers.vue';

import {
  createNewItem
} from '@/api/providers/poll';

export default {
  components: {
    BasicCard,
    MaterialButton,
    MaterialInput,
    PollAnswers,
    PollMembers,
  },

  data() {
    return {
      title: '',
      description: '',
      endDate: undefined,
      isRestricted: false,
      invitedUsers: [],
      invitedGroups: [],
    }
  },

  methods: {
    ...mapActions('notification', { addNotification: 'add' }),

    async submit() {
      if (this.pollDataIsInvalid()) return;

      try {
        const result = await createNewItem({
          "question": this.title,
          "description": this.description,
          "endDate": new Date(this.endDate).toISOString(),
          "pollOptions": this.getOptions(),
          "isRestricted": this.isRestricted,
        });

        if (result === undefined) {
          throw new Error("Kon poll niet aanmaken");
        }

        await this.$refs.pollMembersRef.submit(result.id)

        this.addNotification({
          description: 'Poll aangemaakt',
          icon: { component: 'poll' }
        });

        this.$router.push('/polls');
      }
      catch (e) {
        this.addNotification({
          description: e.message,
          icon: { component: 'cancel' }
        });
      }
    },

    pollHasNoTitle() {
      return this.title === '';
    },

    pollHasNoDescription() {
      return this.description === '';
    },

    pollHasInsufficientOptions() {
      return (this.$refs.pollAnswersRef?.options ?? []).length < 2;
    },

    pollHasInvalidDate() {
      try {
        const pollEndDate = new Date(this.endDate);
        return pollEndDate.toISOString() === undefined || pollEndDate < new Date();
      }
      catch {
        return true;
      }
    },

    pollDataIsInvalid() {
      if (this.pollHasNoTitle()) {
        this.addNotification({
          description: 'Poll-titel is verplicht.',
          icon: { component: 'poll' }
        });
        return true;
      }

      if (this.pollHasInsufficientOptions()) {
        this.addNotification({
          description: 'Poll moet minstens 2 opties bevatten.',
          icon: { component: 'poll' }
        });
        return true;
      }

      if (this.pollHasInvalidDate()) {
        this.addNotification({
          description: 'Poll moet moet een einddatum in de toekomst hebben.',
          icon: { component: 'poll' }
        });

        return true;
      }

      return false;
    },

    getOptions() {
      return (this.$refs.pollAnswersRef?.options ?? [])
        .filter(o => !o.isDeleted)
        .map(o => { return { anwser: o.answer } });
    }
  }
}
</script>